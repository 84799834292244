import { h } from 'preact';
import { Link } from 'preact-router/match';

import style from './footer.scss';

const Footer = () => {
    return (
        <footer class="container">
            <div class={style.footer}>
                timeTable | Made with ❤️ but in a hurry | <Link href="/about">About</Link> | <Link href="/stations">Stations</Link>
            </div>
        </footer>
    );
};

export default Footer;
